import * as types from '../mutation-types';
import Vue from 'vue';

const state = {
  version: {
    status: false,
    data: {}
  },
  wdm: { // wdm = wisdom_data_model
    status: false,
    ful: {} // ful = follow_up_location
  },
  department: {
    status: false,
    ful: {} // ful = follow_up_location
  },
  websocket: {
    ws_chemo_list: false,
    patient_id: false,
    status: false,
    socket_connection: '',
    id: ''
  },
  device: {
    isMobile: false,
    isTablet: false
  },
  sidebar: {
    opened: false,
    hidden: false,
    control_on_user: false
  },
  effect: {
    translate3d: true
  },
  patient: {
    selected: false
  },
  help: {
    show: false,
    no: false
  },
  test: {
    mode: false,
  },
  modal: {
    show: false,
    data: {}
  },
  loading: {
    status: false,
    data: {'label': ''}
  },
  messenger_data: {
    list: []
  },
  lang: '',
  rule_queue: [],
  account_list: [],
  user: {'username': ''},
  screen_size: {
    'width': 0,
    'height': 0
  }
};

const mutations = {
  [types.TOGGLE_DEVICE](state, device) {
    state.device.isMobile = device === 'mobile';
    state.device.isTablet = device === 'tablet';
  },

  [types.TOGGLE_SIDEBAR](state, opened) {
    if (state.device.isMobile) {
      state.sidebar.opened = opened;
    } else {
      state.sidebar.opened = true;
    }
  },

  [types.Loading](state, loading_data) {
    state.loading = loading_data;
  },

  [types.sidebar_control_on_user](state) {
    if (state.sidebar.hidden) {
      state.sidebar.hidden = false;
    } else {
      state.sidebar.hidden = true;
    }
    if (state.sidebar.opened) {
      state.sidebar.opened = false;
    } else {
      state.sidebar.opened = true;
    }
  },

  [types.sidebar_control](state, close_or_open) {
    state.sidebar.hidden = close_or_open;
  },

  [types.SWITCH_EFFECT](state, effectItem) {
    for (let name in effectItem) {
      state.effect[name] = effectItem[name];
    }
  },

  [types.selected_patient](state, data) {
    if (data) {
      state.patient = data;
      state.patient.selected = true;
    } else {
      state.patient = {};
      state.patient.selected = false;
      document.getElementById('bodyWisdomEra').classList.remove('sidebar-lg-show');
      document.getElementById('bodyWisdomEra').classList.remove('sidebar-show');
    }
  },

  [types.ws_chemo_list](state, ischemolist) {
    if (ischemolist) {
      state.websocket.ws_chemo_list = true;
    } else {
      state.websocket.ws_chemo_list = false;
    }
  },

  [types.ws_status](state, websocket_status) {
    state.websocket.status = websocket_status;
  },

  [types.websocket_id](state, data) {
    state.websocket.id = data.id;
  },

  [types.ws_socket_connection](state, socket_connection) {
    state.websocket.socket_connection = socket_connection;
  },


  [types.lang](state, lang) {
    state.lang = lang;
  },

  [types.screen_size](state, screen_size) {
    state.screen_size = screen_size;
  },

  [types.modal](state, data) {
    state.modal.data = data;
  },

  [types.user](state, data) {
    state.user = data;
  },

  [types.modal_show](state, status) {
    state.modal.show = status;
  },

  [types.rule_queue](state, data) {
    Vue.set(state.rule_queue, state.rule_queue.length, data);
  }
};

export default {
  state,
  mutations
};
