import { default as router } from '@/router';
import Vue from 'vue';

// URL and endpoint constants
import { API_BASE_URL, CLIENT_ID, CLIENT_SECRET, GRANT_TYPE } from '@/config';
const LOGOUT_URL = API_BASE_URL + 'v1/logout';
import LoginService from '@/services/login';
import store from '@/store';

/* global localStorage */

export default {

  // User object will let us check authentication status
  user: {
    authenticated: false
  },

  // Send a request to the login URL and save the returned access_token
  login(context, creds, redirect) {
    creds['grant_type'] = 'password'
    return new Promise((resolve, reject) => {
      /*
        login by using example user data.
      */
      let user = require('@/example_data/user').example_data;
      user.username = creds.username;
      this.user.authenticated = true;
      localStorage.setItem('user', JSON.stringify(user));
      resolve('success');
      setTimeout(function() {
        router.push(redirect);
        location.reload();
      }.bind(this), 1000)
      /*
        store.commit('Loading', { 'status': true, 'data': { 'label': 'Sistem verileri hazırlanıyor lütfen bekleyiniz.' } });
        let query = 'username=' + creds['username'] + '&' + 'password=' + creds['password']
        LoginService.login(query)
          .then(resp => {
            if (resp.data.status_code === "33000" && resp.data.login_details && resp.data.access_token) {
              this.user.authenticated = true;
              localStorage.setItem('refresh_token', resp.data.refresh_token);
              localStorage.setItem('access_token', resp.data.access_token);
              localStorage.setItem('wmanager_refresh_token', resp.data.wmanager_refresh_token);
              localStorage.setItem('wmanager_access_token', resp.data.wmanager_access_token);
              localStorage.setItem('user', JSON.stringify(resp.data.login_details));
              if (redirect) {
                router.push(redirect);
              }
              store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              resolve('success');
            } else {
              store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              reject('error');
            }
          }), resp => {
            store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            reject('error');
          };
      */
    });
  },

  logout(context, redirect) {
    /*
      logout by using example user data.
    */
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    this.user.authenticated = false;
    // console.log('redirect : ', redirect);
    if (redirect) {
      router.push(redirect);
    }
    /*
        context.$http.post(LOGOUT_URL, { 'logout': true })
          .then(data => {
            // console.log(data);
            if (localStorage.getItem('user')) {
              localStorage.removeItem('user');
            }
            if (localStorage.getItem('access_token')) {
              localStorage.removeItem('access_token');
            }
            if (localStorage.getItem('wmanager_access_token')) {
              localStorage.removeItem('wmanager_access_token');
            }
            if (localStorage.getItem('refresh_token')) {
              localStorage.removeItem('refresh_token');
            }
            if (localStorage.getItem('wmanager_refresh_token')) {
              localStorage.removeItem('wmanager_refresh_token');
            }
            this.user.authenticated = false;
            if (redirect) {
              router.go(redirect);
            }
          }, response => {
            router.push(redirect);
            context.error = response.data.error;
          });
    */
  },

  checkAuth() {
    var access_token = localStorage.getItem('access_token');
    if (access_token) {
      this.user.authenticated = true;
    } else {
      this.user.authenticated = false;
    }
  },

  // The object to be passed as a header for authenticated requests
  getAuthHeader() {
    return 'Bearer ' + localStorage.getItem('access_token');
  },

  // In Auth service

  checkUnauthorizedToken(response, request_body) {
    // console.log('checkUnauthorizedToken ', response);
    if (response.status === 401 && response.statusText === 'Unauthorized') {
      if (localStorage.getItem('access_token')) {
        localStorage.removeItem('access_token');
        this.checkAuth();
      }
      if (router.currentRoute.path !== '/welcome') {
        router.push('/welcome');
      }
    }
  },

};
