//
const IS_LOCAL = process.env.NODE_ENV !== 'production';

const API_BASE_URL = IS_LOCAL ?
  'http://localhost:33000/' :
  'https://wdigitalchannel-api.mlpcare.com/';

const WDMR_URL = IS_LOCAL ?
  'https://wdmr.mlpcare.com/' :
  'https://wdmr.mlpcare.com/';

const API_WANALYZER_FRONTEND_URL = 'https://wanalyzer.mlpcare.com'

const WDMR_LINKS = {
  'record': WDMR_URL + '#/wdmr-record?',
  'show': WDMR_URL + '#/wdmr-show?',
  'wdmr_data_show': WDMR_URL + '#/wdmr-data-show?',
  'wdmr_data_record': WDMR_URL + '#/wdmr-data-record?'
};

const table_match = {
  'TreatmentSchemaSystem': 'TreatmentSchemaSystem'
};

const version = '2.0.1 DEMO';

export {
  version,
  IS_LOCAL,
  API_BASE_URL,
  WDMR_LINKS,
  WDMR_URL,
  API_WANALYZER_FRONTEND_URL
};
