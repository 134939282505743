import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue)
import App from './App';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync';
sync(store, router);
import Resource from 'vue-resource';
Vue.use(Resource);
import * as filters from './filters';
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

const { state } = store;
import VueNativeSock from 'vue-native-websocket';
import auth from './auth';
auth.checkAuth();

Vue.http.interceptors.push(function(request, next) {
  // Add Authorization to all requests
  request.headers.set('Authorization', auth.getAuthHeader());
  if (request.url !== 'api/token/refresh') {}
  // Check for expired token response, if expired, refresh token and resubmit original request
  next(function(response) {}, response => {
    console.log('http request error: ', response)
  });
}.bind(this));

const IS_LOCAL = process.env.NODE_ENV !== 'production';
if (IS_LOCAL) {
  Vue.config.devtools = true;
}
router.beforeEach((to, from, next) => {
  var access_token = localStorage.getItem('access_token');
  var ws_url = '';
  next();
});


const app = new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
export { app, router, store };
