import Vue from 'vue'
import Router from 'vue-router'
import menuModule from '@/store/modules/menu';

const bsapage = () => import('@/views/pages/bsapage')
const databasetablearchitecture = () => import('@/views/pages/databasetablearchitecture')
const databasetablelist = () => import('@/views/pages/databasetablelist')
const databasetables = () => import('@/views/pages/databasetables')
const defaultcontainer = () => import('@/containers/defaultcontainer')
const diagnosis = () => import('@/views/pages/diagnosis')
const drugapproval = () => import('@/views/pages/drugapproval')
const gfrpage = () => import('@/views/pages/gfrpage')
const login = () => import('@/views/pages/login')
const logout = () => import('@/views/pages/logout')
const newtreatment = () => import('@/views/pages/newtreatment')
const treatmentcycleincreasepage = () => import('@/views/pages/treatmentcycleincreasepage')
const treatmentdatechangepage = () => import('@/views/pages/treatmentdatechangepage')
const treatmentdaymanagement = () => import('@/views/pages/treatmentdaymanagement')
const treatmentdrugarrangepage = () => import('@/views/pages/treatmentdrugarrangepage')
const treatmentdrugcycledayarrangement = () => import('@/views/pages/treatmentdrugcycledayarrangement')
const treatmentlist = () => import('@/views/pages/treatmentlist')
const treatmentschemadrugs = () => import('@/views/pages/treatmentschemadrugs')
const treatmentschemaselection = () => import('@/views/pages/treatmentschemaselection')
const unittreatmentlist = () => import('@/views/pages/unittreatmentlist')
const version = () => import('@/views/pages/version')
const welcome = () => import('@/views/pages/welcome')
const worklisttotal = () => import('@/views/pages/worklisttotal')
const worklisttotaltable = () => import('@/views/pages/worklisttotaltable')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [{
    path: '/',
    redirect: 'welcome',
    name: 'welcome',
    component: defaultcontainer,
    children: [{
        path: '/welcome',
        name: 'welcome',
        component: welcome
      },
      {
        path: '/login',
        name: 'login',
        component: login
      },
      {
        path: '/logout',
        name: 'logout',
        component: logout
      },
      {
        path: '/bsapage',
        name: 'bsapage',
        component: bsapage
      },
      {
        path: '/gfrpage',
        name: 'gfrpage',
        component: gfrpage
      },
      {
        path: '/treatmentschemaselection',
        name: 'treatmentschemaselection',
        component: treatmentschemaselection
      },
      {
        path: '/treatmentschemadrugs',
        name: 'treatmentschemadrugs',
        component: treatmentschemadrugs
      },
      {
        path: '/diagnosis',
        name: 'diagnosis',
        component: diagnosis
      },
      {
        path: '/databasetables',
        name: 'databasetables',
        component: databasetables
      },
      {
        path: '/newtreatment',
        name: 'newtreatment',
        component: newtreatment
      },
      {
        path: '/treatmentlist',
        name: 'treatmentlist',
        component: treatmentlist
      },
      {
        path: '/treatmentdaymanagement',
        name: 'treatmentdaymanagement',
        component: treatmentdaymanagement
      },
      {
        path: '/drugapproval',
        name: 'drugapproval',
        component: drugapproval
      },
      {
        path: '/treatmentdatechangepage',
        name: 'treatmentdatechangepage',
        component: treatmentdatechangepage
      },
      {
        path: '/treatmentdrugarrangepage',
        name: 'treatmentdrugarrangepage',
        component: treatmentdrugarrangepage
      },
      {
        path: '/treatmentcycleincreasepage',
        name: 'treatmentcycleincreasepage',
        component: treatmentcycleincreasepage
      },
      {
        path: '/treatmentdrugcycledayarrangement',
        name: 'treatmentdrugcycledayarrangement',
        component: treatmentdrugcycledayarrangement
      },
      {
        path: '/unittreatmentlist',
        name: 'unittreatmentlist',
        component: unittreatmentlist
      },
      {
        path: '/databasetablearchitecture',
        name: 'databasetablearchitecture',
        component: databasetablearchitecture
      },
      {
        path: '/databasetablelist',
        name: 'databasetablelist',
        component: databasetablelist
      },
      {
        path: '/worklisttotal',
        name: 'worklisttotal',
        component: worklisttotal
      },
      {
        path: '/worklisttotaltable',
        name: 'worklisttotaltable',
        component: worklisttotaltable
      },
      {
        path: '/version',
        name: 'version',
        component: version
      },
    ]
  }]
})
